import React from 'react';
import Layout from '../components/layout';
import '../components/layout.scss';
import * as styles from './index.module.scss';
import SEO from '../components/seo';
import Slider from '../components/Slider';
import homeQualite from '../images/icons/home_qualite.svg';
import homeDelais from '../images/icons/home_delais.svg';
import homeServicePerso from '../images/icons/home_service-perso.svg';
import homeCommercial from '../images/icons/commercial-terrain.svg';
import homeDeviseur from '../images/icons/deviseurs-fabricants.svg';
import homePrix from '../images/icons/home_prix.svg';
import homeServiceClient from '../images/icons/home_service-client.svg';
import homeCapaciteProduction from '../images/icons/home_capacité-production.svg';
import homeTechniquesImpression from '../images/icons/home_techniques-impression.svg';
import ruler from '../images/icons/ruler.svg';
import quoteOpen from '../images/icons/quote-open.svg';
import quoteClose from '../images/icons/quote-close.svg';
import fichierReception from '../images/icons/fichier-reception.svg';
import fichierControle from '../images/icons/fichier-controle.svg';
import fichierFabrication from '../images/icons/fichier-fabrication.svg';
import fichierExpedition from '../images/icons/fichier-expedition.svg';
import arrowRight from '../images/icons/arrow-right.svg';
import tick from '../images/icons/tick.svg';
import { Link } from 'gatsby';
import logoOccitanie from '../images/partenaires/logo_occitanie.png';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Slider />
    <section className={styles.metier}>
      <div className="container-fluid">
        <div className="row mb0">
          <div className="col-lg-2">
            <div className={styles.metierImg1} />
          </div>
          <div className="col-lg-8 text-center">
            <h1>Notre métier</h1>
            <p>
              Nous mettons à votre disposition une expertise en matière d’
              <strong>
                impression de supports de communication et publicitaires
              </strong>
              , en vous proposant un large choix pour vos projets : papier,
              plastique, tissus, adhésif, …, et ce afin de répondre à toutes vos
              demandes.
            </p>
            <h2>
              In-Print, c'est une offre complète à travers deux activités bien
              identifiées :
            </h2>
            <div className={styles.activites}>
              <div>
                <span className={`${styles.number} bg-primary`}>1</span>
                <div className={` ${styles.activiteGrandFormat} card`}>
                  <h4 className="primary">
                    Spécialiste
                    <br />
                    du Grand Format
                  </h4>
                  <p>
                    Des supports Grand Format souples ou rigides jusqu'à 3m20 de
                    large
                  </p>
                  <button
                    onClick={() => animateScroll('#grand-format')}
                    className={`${styles.btn} ${styles.btnPrimary}`}
                  >
                    En savoir plus
                  </button>
                </div>
              </div>
              <div>
                <span className={`${styles.number} bg-secondary`}>2</span>
                <div className={` ${styles.activiteTousSupports} card`}>
                  <h4 className="secondary">
                    Expert
                    <br />
                    en impression tous supports
                  </h4>
                  <p>Une offre globale de supports de communication</p>
                  <button
                    onClick={() => animateScroll('#tous-supports')}
                    className={`${styles.btn} ${styles.btnSecondary}`}
                  >
                    En savoir plus
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2">
            <div className={styles.metierImg2} />
          </div>
        </div>
      </div>
    </section>
    <section id="grand-format" className={` parallax ${styles.grandFormat}`}>
      <div className="large-container">
        <div className="row mb0">
          <div className="col-lg-8">
            <div className=" card text-center">
              <h2 className={`primary ${styles.homeH2}`}>
                Spécialiste du Grand Format
              </h2>
              <p>
                Au sein de notre atelier de fabrication, nous assurons la
                production numérique de{' '}
                <strong>supports Grand Format souples et rigides</strong> :
                Roll’up, PLV, adhésif, bâche, stands, aménagement d’intérieur et
                panneaux en PVC, Dibond, Aquilux, Plexiglass, carton.
              </p>
              <p className="secondary bold">
                Notre atout majeur pour vous garantir une prestation de qualité
                : un atelier équipé de machines de dernière génération.
              </p>
              <p className="text-center">
                Notre atelier de fabrication, d’une surface de 800m², gère
                toutes les demandes d’impressions standards et sur mesure grâce
                à un parc machine très performant :
              </p>
              <div className={styles.machines}>
                <div>
                  <div className={styles.machinesImg}>
                    <img src={tick} />
                  </div>
                  <div className={styles.machinesText}>
                    <span>Imprimantes Roll-To-Roll</span>
                    <br />
                    <span className="secondary-dark">
                      Karibu SwissQprint
                    </span>
                  </div>
                </div>
                <div>
                  <div className={styles.machinesImg}>
                    <img src={tick} />
                  </div>
                  <div className={styles.machinesText}>
                    <span>
                      Tables à plat
                      <br />
                    </span>
                    <span className="secondary-dark">Nyala SwissQprint</span>
                  </div>
                </div>
                <div>
                  <div className={styles.machinesImg}>
                    <img src={tick} />
                  </div>
                  <div className={styles.machinesText}>
                    <span>
                     Tables de découpe
                      <br />
                    </span>
                    <span className="secondary-dark">Zund</span>
                  </div>
                </div>
              </div>
              <p className="text-center">
                Nous effectuons également les opérations de finition comme la
                plastification Grand Format (laminateur).
              </p>
              <p>
                La <strong>technicité </strong>de nos équipes de production et
                la <strong>qualité</strong> du produit fini font d’In-Print un
                spécialiste du Grand Format, ce qui est confirmé par une
                <strong> satisfaction</strong> client élevée.
              </p>
              <Link
                className={`${styles.btn} ${styles.btnPrimary}`}
                to="/grand-format"
              >
                En savoir plus sur les produits Grand Format
              </Link>
            </div>
          </div>
          <div className="col-lg-4">
            <div
              className={`${
                styles.dimensionsContainer
              } bg-medium-primary text-center`}
            >
              <img src={ruler} />
              <span className={styles.line1}>
                Impression
                <br />
              </span>
              <span className={styles.line2}>
                jusqu'à
                <br />
              </span>
              <span className={styles.dimensions}>
                3m20
                <br />
              </span>
              <span className={styles.line4}>de large</span>
              <img src={ruler} />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className={`${styles.icoBanner} bg-grey`}>
      <div className="container">
        <div className="text-center row mb0">
          <div className="col-sm-12">
            <h3>Accompagnement humain et points de contrôle à chaque étape</h3>
          </div>
        </div>
        <div className={`${styles.steps} text-center`}>
          <div className={styles.picto}>
            <img src={fichierReception} />
            <p className="m0">Réception du fichier</p>
          </div>
          <div className={styles.arrow}>
            <img src={arrowRight} />
          </div>
          <div className={styles.picto}>
            <img src={fichierControle} />
            <p className="m0">Contrôle du fichier par des graphistes</p>
          </div>
          <div className={styles.arrow}>
            <img src={arrowRight} />
          </div>
          <div className={styles.picto}>
            <img src={fichierFabrication} />
            <p className="m0">Fabrication</p>
          </div>
          <div className={styles.arrow}>
            <img src={arrowRight} />
          </div>
          <div className={styles.picto}>
            <img src={fichierExpedition} />
            <p className="m0">Expédition</p>
          </div>
        </div>
      </div>
    </section>
    <section id="tous-supports" className={styles.tousSuports}>
      <div className="large-container">
        <div className="text-center row mb0">
          <div className="col-lg-4">
            <div className={styles.tousSupportsImg} />
          </div>
          <div className="col-lg-8">
            <div className="card">
              <h2 className={styles.homeH2}>
                Expert en impression tous supports
              </h2>
              <p>
                Parce que vos projets nécessitent un large savoir-faire, nous
                vous proposons une <strong>offre globale </strong>
                de supports de communication, au-delà de notre production Grand
                Format.
              </p>
              <p className="secondary bold">
                Notre bureau de fabrication s’appuie sur un réseau de
                partenaires de confiance
              </p>
              <p className="text-center">
                Profitez de tous les bénéfices In-Print :
              </p>
              <div className={styles.benefices}>
                <div>
                  <div className={styles.beneficesImg}>
                    <img src={homeServiceClient} />
                  </div>
                  <div className={styles.beneficesText}>
                    <p>
                      Un <strong>interlocuteur unique </strong>In-Print qui gère
                      tous vos besoins, du conseil à la livraison, pour
                      rationnaliser vos coûts.
                    </p>
                  </div>
                </div>
                <div>
                  <div className={styles.beneficesImg}>
                    <img src={homeCapaciteProduction} />
                  </div>
                  <div className={styles.beneficesText}>
                    <p>
                      Une <strong>capacité de production importante </strong>qui
                      vous assure fiabilité et disponibilité et des prix
                      avantageux grâce à notre politique de volume.
                    </p>
                  </div>
                </div>
                <div>
                  <div className={styles.beneficesImg}>
                    <img src={homeTechniquesImpression} />
                  </div>
                  <div className={styles.beneficesText}>
                    <p>
                      <strong>L’optimisation</strong> et la{' '}
                      <strong>
                        maîtrise de l’ensemble des technologies d’impression
                      </strong>{' '}
                      : numérique, offset, rotative, sublimation, …
                    </p>
                  </div>
                </div>
              </div>
              <Link
                className={`${styles.btn} ${styles.btnSecondary} `}
                to="/impression-tous-supports"
              >
                En savoir plus sur les produits tous supports
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className={`parallax ${styles.partenariat}`}>
      <div className="large-container">
        <div className="row mb0">
          <div className="col-lg-2" />
          <div className="col-lg-8 text-center">
            <div className=" card bg-white">
              <h2 className={styles.homeH2}>In-Print vous accompagne </h2>
              <p>
                Nous avons l’ambition de construire un vrai
                <strong> partenariat</strong> avec vous, depuis l’analyse de vos
                besoins et de vos contraintes,
                <br /> jusqu’à la livraison d’une solution sur mesure.
              </p>
              <p>
                Pour cela, nous misons sur un
                <strong> accompagnement global</strong> :
              </p>
              <div className={`${styles.accompagnement} text-center`}>
                <div>
                  <div className={styles.accompagnementIco}>
                    <img src={homeDeviseur} />
                  </div>
                  <div className={styles.accompagnementText}>
                    <span>
                      <strong>Une équipe de deviseurs-fabricants </strong>
                      que vous pouvez contacter pour vos demandes techniques du
                      lundi au vendredi
                    </span>
                  </div>
                </div>
                <div>
                  <div className={styles.accompagnementIco}>
                    <img src={homeCommercial} />
                  </div>
                  <div className={styles.accompagnementText}>
                    <span>
                      <strong>Une présence commerciale terrain </strong>pour
                      venir vous présenter nos produits, nos nouveautés et nos
                      process afin de faciliter notre collaboration
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.reactivite}>
                <img src={quoteOpen} />
                <p className="m0 text-center">
                  Et pour chaque salarié d’In-Print, un seul crédo :
                  <span className="primary"> la RÉACTIVITÉ !</span>
                </p>
                <img src={quoteClose} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className={`${styles.icoBanner} bg-grey`}>
      <div className="container">
        <div className="text-center row mb0">
          <div className="col-sm-12">
            <h3>In-Print, toujours là pour vous</h3>
          </div>
        </div>
        <div className={`${styles.steps} text-center`}>
          <div className={styles.picto}>
            <img src={homeQualite} />
            <p className="m0">Une qualité maîtrisée</p>
          </div>
          <div className={styles.picto}>
            <img src={homePrix} />
            <p className="m0">Des prix compétitifs</p>
          </div>
          <div className={styles.picto}>
            <img src={homeDelais} />
            <p className="m0">Des délais respectés</p>
          </div>
          <div className={styles.picto}>
            <img src={homeServicePerso} />
            <p className="m0">Un service personnalisé</p>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;

function animateScroll(targetSelector) {
  const targetPosition = getElementOffsetTop(
    document.querySelector(targetSelector)
  );

  console.log('targetPosition', targetPosition);

  const initialPosition = window.scrollY;
  const SCROLL_DURATION = 30;
  const maxDistance = document.body.scrollHeight - window.innerHeight;

  const scrollDistance =
    maxDistance > targetPosition
      ? targetPosition - initialPosition
      : maxDistance;
  const stepX = Math.PI / SCROLL_DURATION;
  let stepCount = 0;
  if (window.requestAnimationFrame) {
    requestAnimationFrame(step);
  }

  function step() {
    if (stepCount < SCROLL_DURATION) {
      requestAnimationFrame(step);
      stepCount++;
      window.scrollTo(
        0,
        initialPosition +
          scrollDistance * 0.22 * Math.pow(1 - Math.cos(stepX * stepCount), 2)
      );
    }
  }
}

const getElementOffsetTop = (element, currentOffset = 0) => {
  console.log('element', element);

  if (element.offsetParent) {
    return getElementOffsetTop(
      element.offsetParent,
      currentOffset + element.offsetTop
    );
  }

  return currentOffset;
};
